import palette from './palette';
import typography from './typography';
import { createTheme } from '@material-ui/core';
import overrides from './overrides';

const theme = createTheme({
    palette,
    typography,
    overrides
});


theme.typography.h1 = {
    ...theme.typography.h1,
    [theme.breakpoints.down('sm')]: {
        fontSize: "32px",
        lineHeight: "36px"
    },
}

export default theme;