import palette from '../palette';

export default {
    root: {
        // color: "transparent",
        // "& .MuiIconButton-label": {
        //     position: "relative",
        //     zIndex: 0
        // },
        // "&:not($checked) .MuiIconButton-label:after": {
        //     content: '""',
        //     left: 4,
        //     top: 4,
        //     height: 15,
        //     width: 15,
        //     position: "absolute",
        //     backgroundColor: "white",
        //     zIndex: -1
        // }
        "&. MuiTypography-root": {
            color: palette.primary.main
        },
    },
    label: {
        color: "red"
    }
};
