import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import deepForceUpdate from 'react-deep-force-update';
import queryString from 'query-string';
import { createPath } from 'history';
import App from './components/App';
import createFetch from './createFetch';
import history from './history';
import { updateMeta } from './DOMUtils';
import router from './router';
import Page from 'react-page-loading';
import StyleContext from 'isomorphic-style-loader/StyleContext';

// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
/* const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map(x => x._insertCss());
  return () => {
    removeCss.forEach(f => f());
  };
}; */


  /* const jssStyles = document.querySelector('#jss-server-side');
  if (jssStyles) {
    jssStyles.parentElement.removeChild(jssStyles);
  } */

const context = {
  // Universal HTTP client
  history,
  fetch: createFetch(fetch, {
    baseUrl: window.App.apiUrl,
  }),
};

const container = document.getElementById('root');
let currentLocation = history.location;
let appInstance;

const scrollPositionsHistory = {};

// Re-render the app when window.location changes
async function onLocationChange(location, action) {

  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve(context);
    
    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    const insertCss = (...styles) => {
      const removeCss = styles.map(style => style._insertCss())
      return () => removeCss.forEach(dispose => dispose())
    }

    context.isAdminRoute = false;
    context.isZurichRoute = false;

    if(route.admin_route !== undefined && route.admin_route === true) {
      context.isAdminRoute = true;
    } else if (route.zurich_route !== undefined && route.zurich_route === true) {
      context.isZurichRoute = true;
    }

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    appInstance = renderReactApp(
        <App context={context} {...route.props}>
          <StyleContext.Provider value={{insertCss}}>
            <div style={{height: '100%'}}>
              <Page loader={"bar"} color={"#009F3C"} size={6}>
                {route.component}
              </Page>
            </div>
          </StyleContext.Provider>
        </App>,
      container,
      () => {
        if (isInitialRender) {
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = route.title;

        updateMeta('description', route.description);

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }
        
        window.scrollTo(scrollX, scrollY);

        if (window.ga) {
          window.ga('send', 'pageview', createPath(location));
        }
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
}

history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}
