import palette from '../palette';

export default {
    root: {
        textTransform: "unset !important",
    },
    contained: {
        boxShadow: "none"
    }
};
