import React from 'react';
import PropTypes from 'prop-types';

import StyleContext from 'isomorphic-style-loader/StyleContext';
import ApplicationContext from './ApplicationContext';

import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import theme from '../theme';
import frontTheme from '../theme/front';
import zurichTheme from "../theme/zurichtheme";

export default function App({ context, children, isAdminRoute }) {
  /* React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []); */


  let usedTheme = theme;
  if (context.isZurichRoute !== undefined && context.isZurichRoute === true) {
    usedTheme = zurichTheme;
  } else if (context.isAdminRoute !== undefined && context.isAdminRoute === false) {
    usedTheme = frontTheme;
  }

  return (
    <ApplicationContext.Provider value={{ context }}>
      <ThemeProvider theme={usedTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="main-container">
            {/* <Page loader={"bar"} color={"#009F3C"} size={6} > */}
            {React.Children.only(children)}
            {/* </Page> */}
          </div>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApplicationContext.Provider>
  );
}

App.propTypes = {
  // insertCss: PropTypes.func.isRequired,
  context: PropTypes.shape({
    // Universal HTTP client
    // fetch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    query: PropTypes.object,
  }).isRequired,
  children: PropTypes.element.isRequired,
};
