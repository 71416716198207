import palette from '../../palette';

export default {
    root: {
        background: palette.white,
        borderRadius: "12px",
        "&:hover $notchedOutline": {
            borderColor: palette.primary.main
        }
    },
    notchedOutline: {
        borderColor: "transparent",
    },
    input: {
        '&::placeholder': {
            opacity: 0.5,
            color: palette.primary.main
        }
    }
};
