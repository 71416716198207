import palette from './palette';

export default {
    h1: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '48px',
        // letterSpacing: '-0.24px',
        lineHeight: '53px',
    },
    h2: {
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: '32px',
        // letterSpacing: '-0.24px',
        lineHeight: '35px'
    },
    h3: {
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: '24px',
        // letterSpacing: '-0.06px',
        lineHeight: '26px'
    },
    h4: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        // letterSpacing: '-0.06px',
        lineHeight: '24px'
    },
    h5: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '16px',
        letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    h6: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: '14px',
        // letterSpacing: '-0.05px',
        lineHeight: '20px'
    },
    subtitle1: {
        color: palette.text.primary,
        fontSize: '16px',
        // letterSpacing: '-0.05px',
        lineHeight: '25px'
    },
    subtitle2: {
        color: palette.text.secondary,
        fontWeight: 400,
        fontSize: '14px',
        // letterSpacing: '-0.05px',
        lineHeight: '21px'
    },
    body1: {
        color: palette.text.primary,
        fontSize: '14px',
        // letterSpacing: '-0.05px',
        lineHeight: '21px'
    },
    body2: {
        color: palette.text.secondary,
        fontSize: '12px',
        // letterSpacing: '-0.04px',
        lineHeight: '18px',
        fontWeight: 400
    },
    button: {
        color: palette.text.primary,
        fontSize: '14px',
        padding: '14.5px 24px !important',
        fontWeight: 'bold'
    },
    caption: {
        color: palette.text.secondary,
        fontSize: '11px',
        // letterSpacing: '0.33px',
        lineHeight: '13px'
    },
    overline: {
        color: palette.text.secondary,
        fontSize: '11px',
        fontWeight: 500,
        // letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase'
    },
    fontFamily: [
        '"Conv_ZurichSans-Regular"',
    ].join(','),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightThin: 100,
    fontWeightBold: 'bold',
};

